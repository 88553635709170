import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.section`
  padding: 100px var(--gutter-s) 50px;
  @media (min-width: 768px) {
    padding: 150px var(--gutter-l) 50px;
  }
`

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  color: var(--manatee);
`

const Block = styled.div`
  margin-bottom: 2rem;
  h4,
  p {
    color: var(--manatee);
    line-height: 150%;
  }
  h4 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
`

const Privacy = () => {
  return (
    <Layout metaNavbar={true}>
      <SEO
        title="Privacy"
        keywords={[
          `submitt`,
          `submitt app`,
          `medical app`,
          "medical claims",
          "doctors",
        ]}
      />
      <Container>
        <Heading> Privacy </Heading>
        <Block>
          <h4>POPI COMPLIANCE AGREEMENT</h4>
          <p>
            We hereby confirm that our company Submitt is Compliant with the
            Protection of Personal Information Act in respect to the following:
          </p>
          <p>
            <strong>Processing of Personal Information: </strong>As operators we
            undertake to ensure that all personal information that is being
            processed on behalf of our service providers is complete, accurate,
            not misleading, and up to date. We further confirm that all personal
            information will only be used for the single purpose of the billing
            services that is given to our clients.
          </p>
          <p>
            <strong>Security Safeguards: </strong>As operators we confirm that
            the process used of all personal information is done so lawfully, by
            securing the confidentiality, integrity and the accuracy of all
            personal information provided by our service providers.
          </p>
          <p>
            <strong>Technical and Organisational Measures: </strong>As operators
            we validate that the technical and organisational measures that we
            have in place are effective in complying with the internal and
            external risks to safeguard the data rights of all the service
            providers personal information.
          </p>
          <p>
            <strong>Unauthorised Processing: </strong>As operators we confirm
            that we can detect the unauthorised access or acquisition of all
            personal information and will duly notify our service providers
            should such access be detected. We furthermore confirm that all our
            personnel are trained to distinguish between processing that is
            unlawful or in the legitimate interest of our service providers.
            Should a compromise be discovered, we confirm having suitable
            processes and procedures in place to restore the integrity of our
            systems.
          </p>
          <p>
            <strong>Service Provider Capability: </strong>As operators we
            confirm adhering to all lawful conditions by being transparent when
            processing personal information. We further confirm that all
            instructions from our service providers in respect to the deletion
            and destruction of personal information when information is not
            accurate, irrelevant, excessive, out of date, incomplete or obtained
            unlawfully is adhered to by us and we furthermore confirm assuring
            our service providers that all instructions are immediately obeyed
            and followed through.
          </p>
          <p>
            <strong>Suspension and Termination: </strong>As operators we advise
            that should a termination or suspension of a contract occur between
            us and our service providers, we remain responsible of the personal
            information that was provided to us and should the need arise,
            enable the data subject participation between us and our service
            providers.
          </p>
        </Block>
        <Block>
          <h4>WHAT DO WE DO WITH YOUR INFORMATION?</h4>
          <p>
            When you use our app, we save the personal information you provide
            us with such as your first name, last name, email address and phone
            number. When you visit our website, we also automatically receive
            your computer’s IP address that helps us learn about your browser
            and operating system.
          </p>
        </Block>
        <Block>
          <h4>CONSENT</h4>
          <p>
            When you provide us with personal information to start your
            subscription or verify your credit card, we imply that you consent
            to our collecting it and using it for that specific reason only.
          </p>
          <p>
            If we ask for your information for marketing, we will either ask you
            directly for your consent, and provide you with the option to say
            no.
          </p>
          <p>
            If after you opt-in to marketing and you change your mind, you may
            withdraw your consent by emailing us at info@submitt.appDISCLOSUREWe
            may disclose your personal information if required to do so by
            law.PAYMENTWe use Stripe to process payments. They are our
            PCI-compliant payments partner and trusted worldwide. PCI-DSS
            requirements help ensure the secure handling of credit card
            information by our app and its service providers.
          </p>
        </Block>

        <Block>
          <h4>THIRD PARTY SERVICES</h4>
          <p>
            Our third-party partners used will only collect, use and disclose
            your information necessary to allow them to perform the services
            required by Submitt.Certain third-party service providers have their
            own privacy policies in respect to the information required for
            purchase-related transactions. We recommend that you read these
            privacy policies so you can understand the manner in which your
            personal information will be handled by these providers.
          </p>
          <p>
            <strong>Links: </strong> When you click on links on our website,
            they may direct you away from our site. We are not responsible for
            their privacy practices.
          </p>
        </Block>

        <Block>
          <h4>SECURITY</h4>
          <p>
            To protect your personal information, we take reasonable precautions
            and follow industry best practices to make sure it is not
            inappropriately lost, misused, accessed, disclosed, altered or
            destroyed. We do not store any credit card details, these are
            securely stored by our third-party partner Stripe.
          </p>
        </Block>

        <Block>
          <h4>AGE OF CONSENT</h4>
          <p>
            By using this site, you represent that you are at least the age of
            majority in your state or province of residence and you have given
            us your consent to allow any of your minor dependents to use this
            site.
          </p>
        </Block>

        <Block>
          <h4>UPDATES</h4>
          <p>
            We reserve the right to modify this privacy policy at any time, so
            please review it frequently. 
          </p>
          <p>
            If our app is acquired or merged with another company, your
            information may be transferred to the new owners so that we may
            continue to sell products to you.
          </p>
        </Block>
      </Container>
    </Layout>
  )
}

export default Privacy
